'use client'
import { useEffect } from 'react';
import { supabase } from 'utils/supabaseClient';

export default function Home(): JSX.Element {
  useEffect(() => {
    const checkUser = async () => {
      try {
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession();

        if (sessionError || !sessionData?.session) {
          // If no session or an error occurred, redirect to sign-in page
          window.location.href = '/auth/sign-in';
          return;
        }

        const { email } = sessionData.session.user;

        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('user_type')
          .eq('email', email)
          .single();

        if (userError || !userData) {
          // Handle the case where the user data couldn't be fetched
          console.error('Error fetching user:', userError);
          window.location.href = '/auth/sign-in';
          return;
        }

        const { user_type } = userData as { user_type: string };

        if (user_type === 'employer') {
          // Redirect employers to the command center
          window.location.href = '/dashboard/commandcenter';
        } else if (user_type === 'seeker') {
          // Fetch seeker username based on email
          const { data: seekerData, error: seekerError } = await supabase
            .from('seekers')
            .select('username')
            .eq('email', email)
            .single();

          if (seekerError || !seekerData) {
            console.error('Error fetching seeker:', seekerError);
            window.location.href = '/auth/sign-in';
            return;
          }

          const { username } = seekerData as { username: string };

          // Redirect seekers to their profile page
          window.location.href = `/dashboard/profile/${username}`;
        } else {
          // Handle any other user types or errors
          window.location.href = '/auth/sign-in';
        }
      } catch (error) {
        // Handle any unexpected errors
        console.error('Unexpected error:', error);
        window.location.href = '/auth/sign-in';
      }
    };

    checkUser();
  }, []);

  return null; // Render nothing while the redirect is in process
}
